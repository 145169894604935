<template>
  <div class="auth">
    <auth-header></auth-header>
    <div class="content">
      <router-view></router-view>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import AuthHeader from "./components/AuthHeader";
import { FooterMenu } from "@/components/common";

export default {
  name: "Auth",
  components: {
    AuthHeader,
    FooterMenu
  },
  provide() {
    return {
      profile: null
    };
  },
  mounted() {
    this.$analytics.setGuest();
  }
};
</script>

<style lang="scss">
.auth {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .auth-header {
    flex: 0 0 auto;
  }
  .content {
    min-height: calc(100vh - 385px);
    flex: 1 0 auto;
  }
  .footer-menu {
    flex: 0 0 auto;
  }
}
</style>
