<template>
  <div class="auth-header">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-xl">
        <public-link class="navbar-brand mb-auto" to="/">
          <img alt="Lendly" src="@/assets/logo-fixed.svg" class="d-none d-md-inline-block">
          <img alt="Lendly" src="@/assets/landing-black-logo.png" class="d-inline-block d-md-none">
        </public-link>
        <button class="navbar-toggler mb-auto"
                type="button"
                data-toggle="collapse"
                data-target="#header-menu"
                aria-controls="header-menu"
                aria-expanded="false"
                aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="header-menu">
<!--          <ul class="navbar-nav">-->
<!--            <li class="nav-item">-->
<!--              <public-link class="nav-link" to="/page/how-it-work">-->
<!--                Как это работает-->
<!--              </public-link>-->
<!--            </li>-->
<!--          </ul>-->
          <ul class="navbar-nav ml-md-auto">
            <li class="nav-item">
              <public-link class="nav-link" to="/page/aboutus">
                О платформе
              </public-link>
            </li>
            <li class="nav-item">
              <public-link class="nav-link" to="/page/blog">
                Блог
              </public-link>
            </li>
            <li class="nav-item">
              <public-link class="nav-link" to="/faq">
                FAQ
              </public-link>
            </li>
            <li class="nav-item ml-lg-2">
              <router-link class="btn btn-outline-success" :to="{ name: 'login' }">
                Вход
              </router-link>
            </li>
            <li class="nav-item ml-lg-3 mt-2 mb-2 mt-lg-0 mb-lg-0">
              <public-link class="btn btn-outline-success" to="/auth/register">
                Регистрация
              </public-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { PublicLink } from "@/components/common";

export default {
  name: "AuthHeader",
  components: {
    PublicLink
  }
};
</script>
